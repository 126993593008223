.home-page{	
	width:100%;
	min-height:100vh;
}

.hero{
	width: 100%;
	height:100vh;
	background-color: #263D5A;
	color:#FFF3E7;
	padding:2% 10%;
	background-image: url('../images/gracethecat.png');
	background-position: 70% 47%;
	background-repeat: no-repeat;
	background-size: 561px 600px
}


.hero .tag{
	width:65%;
	position: relative;
	margin-top: 150px;
	font-size: 4em;
	font-weight: 800;
	line-height: 75px;
	margin-bottom: 20px;
}

.hero .sub-tag{
	width:50%;
	position: relative;
	font-size: 1.5em;
	line-height: 35px;
}

.intro{
	width:100%;
	min-height:50vh;
	background-color: #FFF3E7;
	color: #263D5A;
	font-size: 1.5em;
	padding:10%;
}

.intro h3{
	width:100%;
	margin-top:175px;
	text-align: center; 
	color:#1B2430;
}

.intro h4{
	color:#1B2430;
}

.intro ul{
	max-width: 100%;
	padding:0;
	margin:0;
	list-style-type: none;
	display:flex;
	justify-content: space-between;
}

.intro li{
	width:30%;
	margin:0.5%;
	padding:0.5%;
}

.card{
	text-align: center;
}


@media (max-width:600px){
	.hero{overflow: hidden; min-height: 100vh; height: inherit;}
	.hero .tag, .hero .sub-tag{width:100%}
	.intro ul{flex-direction: column;}
	.intro li{width:100%;}
}
@media (min-width: 601px) and (max-width:900px){
	.hero{overflow: hidden;}
	.hero .sub-tag{width:100%}
	.intro ul{flex-direction: column;}
	.intro li{width:100%;}
}