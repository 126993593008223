@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@800&display=swap);
*{
	box-sizing: border-box;
}

body {
  margin: 0;
  padding:0;
  font-family:'Baloo Bhaina 2', cursive; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.subpages{
	font-size:1.2em;
}
.site{	
	width:100%;
	min-height:100vh;
	color:#FFF3E7;
	background-color: #263D5A;
}

footer{
	background-color:#181A1B;
	height:100px;
	padding:2%;
}

a{
	color:#fff;
	text-decoration: none;
}

a:hover{
	color:#FFB021;
	transition:color 0.2s;
}
.hamburger-button{
	display:flex;
	flex-direction: column;
	justify-content: space-around;
	height:24px;
	width:30px;
	background:transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	box-sizing: border-box;
	position: absolute;
	top:10% + 30px;
	right: 2%;
}

.hamburger-button:focus{
	outline: none
}

.hamburger-line{
	width: 30px;
	height:2px;
	background: #fff;
}
.nav{
	margin:0;
	padding:2% 10%;
	margin-left: auto;
	width:100%;
	position: absolute;
	top:0;
}

.nav ul{
	margin: 0;
	padding: 0;
	display: flex;
	list-style-type: none;
}

.nav li{
	font-size: 1.5em;
	display:block;
}

.nav li:not(.logo){
	padding: 10px 30px;
}

.first-link{
	margin-left: auto;
}

.logo{
	padding: 10px 0px;
}

@media (max-width:700px){
	.nav li:not(.logo){display: none}
}
@media (min-width: 701px){
	.hamburger-button{display: none}
}
.home-page{	
	width:100%;
	min-height:100vh;
}

.hero{
	width: 100%;
	height:100vh;
	background-color: #263D5A;
	color:#FFF3E7;
	padding:2% 10%;
	background-image: url(/static/media/gracethecat.0d204d1a.png);
	background-position: 70% 47%;
	background-repeat: no-repeat;
	background-size: 561px 600px
}


.hero .tag{
	width:65%;
	position: relative;
	margin-top: 150px;
	font-size: 4em;
	font-weight: 800;
	line-height: 75px;
	margin-bottom: 20px;
}

.hero .sub-tag{
	width:50%;
	position: relative;
	font-size: 1.5em;
	line-height: 35px;
}

.intro{
	width:100%;
	min-height:50vh;
	background-color: #FFF3E7;
	color: #263D5A;
	font-size: 1.5em;
	padding:10%;
}

.intro h3{
	width:100%;
	margin-top:175px;
	text-align: center; 
	color:#1B2430;
}

.intro h4{
	color:#1B2430;
}

.intro ul{
	max-width: 100%;
	padding:0;
	margin:0;
	list-style-type: none;
	display:flex;
	justify-content: space-between;
}

.intro li{
	width:30%;
	margin:0.5%;
	padding:0.5%;
}

.card{
	text-align: center;
}


@media (max-width:600px){
	.hero{overflow: hidden; min-height: 100vh; height: inherit;}
	.hero .tag, .hero .sub-tag{width:100%}
	.intro ul{flex-direction: column;}
	.intro li{width:100%;}
}
@media (min-width: 601px) and (max-width:900px){
	.hero{overflow: hidden;}
	.hero .sub-tag{width:100%}
	.intro ul{flex-direction: column;}
	.intro li{width:100%;}
}
.about-page{
	min-height:100vh;
	padding:2% 10%;
}

.about-page h1{
	margin-top:150px;
}
.projects-page{
	min-height:100vh;
	padding:2% 10%;
}

.projects-page h1{
	margin-top:150px;
}
.blog-page{
	min-height:100vh;
	padding:2% 10%;
}

.blog-page h1{
	margin-top:150px;
}
.side-drawer{
	height: 100%;
	background: #fff;
	box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
	position: fixed;
	top:0;
	right:0;
	width:70%;
	max-width:400px;
	z-index: 200;
	transform: translateX(100%);
	transition: transform 0.3s ease-out;
	color:#1B2430;
}

.side-drawer ul{
	height:100%;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.side-drawer.open{
	transform: translateX(0);
}

.side-drawer li{
	margin: 0.5rem 0;
}

.side-drawer a{
	color: #1B2430;
	text-decoration: none;
	font-size: 1.2rem
}

.side-drawer a:hover,
.side-drawer a:active{
	color:#AD8BB6;
}

@media (min-width: 696px){
	.side-drawer{
		display:none;
	}
}
.backdrop{
	width:100%;
	height:100%;
	position: fixed;
	top:0px;
	left:0px;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 100;
}
