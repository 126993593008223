.side-drawer{
	height: 100%;
	background: #fff;
	box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
	position: fixed;
	top:0;
	right:0;
	width:70%;
	max-width:400px;
	z-index: 200;
	transform: translateX(100%);
	transition: transform 0.3s ease-out;
	color:#1B2430;
}

.side-drawer ul{
	height:100%;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.side-drawer.open{
	transform: translateX(0);
}

.side-drawer li{
	margin: 0.5rem 0;
}

.side-drawer a{
	color: #1B2430;
	text-decoration: none;
	font-size: 1.2rem
}

.side-drawer a:hover,
.side-drawer a:active{
	color:#AD8BB6;
}

@media (min-width: 696px){
	.side-drawer{
		display:none;
	}
}