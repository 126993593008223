.nav{
	margin:0;
	padding:2% 10%;
	margin-left: auto;
	width:100%;
	position: absolute;
	top:0;
}

.nav ul{
	margin: 0;
	padding: 0;
	display: flex;
	list-style-type: none;
}

.nav li{
	font-size: 1.5em;
	display:block;
}

.nav li:not(.logo){
	padding: 10px 30px;
}

.first-link{
	margin-left: auto;
}

.logo{
	padding: 10px 0px;
}

@media (max-width:700px){
	.nav li:not(.logo){display: none}
}
@media (min-width: 701px){
	.hamburger-button{display: none}
}