.site{	
	width:100%;
	min-height:100vh;
	color:#FFF3E7;
	background-color: #263D5A;
}

footer{
	background-color:#181A1B;
	height:100px;
	padding:2%;
}

a{
	color:#fff;
	text-decoration: none;
}

a:hover{
	color:#FFB021;
	transition:color 0.2s;
}