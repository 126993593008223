.hamburger-button{
	display:flex;
	flex-direction: column;
	justify-content: space-around;
	height:24px;
	width:30px;
	background:transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	box-sizing: border-box;
	position: absolute;
	top:10% + 30px;
	right: 2%;
}

.hamburger-button:focus{
	outline: none
}

.hamburger-line{
	width: 30px;
	height:2px;
	background: #fff;
}