@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@800&display=swap');

*{
	box-sizing: border-box;
}

body {
  margin: 0;
  padding:0;
  font-family:'Baloo Bhaina 2', cursive; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.subpages{
	font-size:1.2em;
}